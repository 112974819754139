import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import PropTypes from 'prop-types'
import SwiperCore, { Navigation } from 'swiper'

import '../../../assets/styles/_sliders.scss'

// install Swiper modules
SwiperCore.use([Navigation])

export default function CardSlider(props) {
  return (
    <Swiper
      navigation={true}
      spaceBetween={20}
      slidesPerView={1.2}
      centeredSlides={true}
      loop={true}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        1240: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
      }}
      className={'zoom-active-slide'}
    >
      {props.cards.map((Card, index) => (
        <SwiperSlide key={index}>{Card}</SwiperSlide>
      ))}
    </Swiper>
  )
}

CardSlider.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.node),
}
